import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { Request } from './config/request';
import VueAxios from 'vue-axios'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

createApp(App).use(ElementPlus,{locale:zhCn}).use(store).use(router).use(VueAxios, Request.init()).mount("#app");
