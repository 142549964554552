import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/index',
		name: 'Index',
		component: () => import('../views/Index.vue')
	},
	{
		path: '/qrCode',
		name: 'QrCode',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/QrCode.vue')
	},
	{
		path: '/analysis',
		name: 'Analysis',
		component: () => import('../views/Analysis.vue')
	},
	{
		path: '/edit',
		name: 'Edit',
		component: () => import('../views/Edit.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/postgraduate',
		name: 'Postgraduate',
		component: () => import('../views/Postgraduate.vue')
	},
	{
		// 订单管理
		path: '/order',
		name: 'Order',
		component: () => import('../views/Order/Order.vue')
	},
	{
		// 订单管理-->上课记录
		path: '/order/record',
		name: 'Record',
		component: () => import('../views/Order/Record.vue')
	},
	{
		// 订单管理-->新建上课记录
		path: '/order/createRecord',
		name: 'CreateRecord',
		component: () => import('../views/Order/CreateRecord.vue')
	},
	{
		// 订单管理-->新建学生上课记录
		path: '/order/createStudent',
		name: 'CreateStudent',
		component: () => import('../views/Order/CreateStudent.vue')
	},
	{
		// 订单管理-->上课记录-->学生上课记录修改
		path: '/order/studentEdit',
		name: 'StudentEdit',
		component: () => import('../views/Order/StudentEdit.vue')
	},
	{
		// 订单管理-->新建研究生上课记录
		path: '/order/createPostgra',
		name: 'CreatePostgra',
		component: () => import('../views/Order/CreatePostgra.vue')
	},
	{
		// 订单管理-->上课记录-->研究生上课记录修改
		path: '/order/postgraEdit',
		name: 'PostgraEdit',
		component: () => import('../views/Order/PostgraEdit.vue')
	},
	{
		// 订单管理-->查看回访计划
		path: '/order/plan',
		name: 'Plan',
		component: () => import('../views/Order/Plan.vue')
	},
	{
		// 订单管理-->新建回访计划
		path: '/order/createPlan',
		name: 'CreatePlan',
		component: () => import('../views/Order/CreatePlan.vue')
	},
	{
		// 订单管理-->修改回访计划
		path: '/order/planEdit',
		name: 'PlanEdit',
		component: () => import('../views/Order/PlanEdit.vue')
	},
	{
		// 订单管理-->回访记录
		path: '/order/visitRecord',
		name: 'VisitRecord',
		component: () => import('../views/Order/VisitRecord.vue')
	},
	{
		path: '/student',
		name: 'Student',
		component: () => import('../views/Student.vue')
	},
	{
		path: '/message',
		name: 'Message',
		component: () => import('../views/Message/Message.vue')
	},
	{
		path: '/message/create',
		name: 'MessageCreate',
		component: () => import('../views/Message/Create.vue')
	},
	{
		path: '/find',
		name: 'Find',
		component: () => import('../views/Find/Find.vue')
	},
]

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(),
	routes
})

export default router
