import { Request } from '@/config/request';
// 获取研究生信息全部数据
export function exportExcel(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/list/exportExcel',
		method: 'get',
		params: parameter
	})
}
//登陆
export function login(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/login',
		method: 'post',
		data: parameter
	})
}
//单点登陆
export function ssoLogin(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/ssoLogin',
		method: 'post',
		data: parameter
	})
}
//退出
export function logout(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/logout',
		method: 'get',
		params: parameter
	})
}
//活动列表
export function advList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/advList',
		method: 'get',
		params: parameter
	})
}
//所有产品列表
export function getAllProduction(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/getAllProduction',
		method: 'get',
		params: parameter
	})
}
//上传海报
export function uploadPoster(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/uploadPoster',
		method: 'post',
		data: parameter
	})
}
//创建活动
export function createNewAdv(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/createNewAdv',
		method: 'post',
		data: parameter
	})
}
//编辑活动
export function editAdv(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/editAdv',
		method: 'post',
		data: parameter
	})
}
//删除广告
export function deleteAdv(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/deleteAdv',
		method: 'get',
		params: parameter
	})
}
//获取学姐的列表
export function getXuejieList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/getXuejieListV2',
		method: 'get',
		params: parameter
	})
}
//获取最新活动的扫码情况
export function getLatestAdv(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/getLatestAdv',
		method: 'get',
		params: parameter
	})
}
//通过id搜索活动
export function getAdvById(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/getAdvById',
		method: 'get',
		params: parameter
	})
}
//获取活动详情
export function getAdvDetail(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/getAdvDetail',
		method: 'get',
		params: parameter
	})
}
//根据学姐id获取此活动的用户信息
export function getXjToUserById(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/getXjToUserById',
		method: 'get',
		params: parameter
	})
}
//获取活动
// GET /api/crm/adv/selectAdv
// 接口ID：12240498
// 接口地址：https://www.apifox.cn/web/project/660726/apis/api-12240498
export function selectAdv(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/selectAdv',
		method: 'get',
		params: parameter
	})
}
// 复制活动
// GET /api/crm/adv/copyAdv
// 接口ID：12279953
// 接口地址：https://www.apifox.cn/web/project/660726/apis/api-12279953
export function copyAdv(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/copyAdv',
		method: 'get',
		params: parameter
	})
}

// 研究生信息-根据院校code查询院系
export function getAcademies(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/adjust/dict/getAcademies',
		method: 'get',
		params: parameter
	})
}
// 研究生信息-根据院系code查询专业
export function getMajors(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/adjust/dict/getMajors',
		method: 'get',
		params: parameter
	})
}
// 研究生信息-根据专业code查询方向
export function getDirections(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/adjust/dict/getDirections',
		method: 'get',
		params: parameter
	})
}

// 研究生信息页面
// 列表
export function getPostgraList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/list/postgraduate',
		method: 'get',
		params: parameter
	})
}
// 删除
export function deletePost(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/deletePostgraduate',
		method: 'get',
		params: parameter
	})
}
// 编辑
export function updatePost(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/updatePostgraduate',
		method: 'post',
		data: parameter
	})
}
// 新增
export function createPost(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/newPostgraduate',
		method: 'post',
		data: parameter
	})
}
// 上传学生证或成绩单
export function uploadImg(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/uploadPostgraduateInfo',
		method: 'post',
		data: parameter
	})
}
// 新建研究生--智能识别
export function regexPos(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/regexPos',
		method: 'post',
		data: parameter
	})
}
export function avatarList(params: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/getAvatarList',
		method: 'get',
		params
	})
}
// 学生信息页面
// excel 导入
export function Excel(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/Excel',
		method: 'post',
		data: parameter
	})
}
// 获取负责人列表
export function getEducationalList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/getEducationalList',
		method: 'get',
		params: parameter
	})
}
// 列表
export function getStudentList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/list/student',
		method: 'get',
		params: parameter
	})
}
// 新增
export function createStudent(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/newStudent',
		method: 'post',
		data: parameter
	})
}
// 快速识别
export function regexStu(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/regexStu',
		method: 'post',
		data: parameter
	})
}
// 编辑学生信息
export function updateStudent(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/updateStudent',
		method: 'post',
		data: parameter
	})
}
// 删除学生信息
export function deleteStudent(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/input/deleteStudent',
		method: 'get',
		params: parameter
	})
}
// 订单页面
// 订单列表
export function getOrderList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/order/orderList',
		method: 'get',
		params: parameter
	})
}
// 新增订单
export function createOrder(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/order/newOrder',
		method: 'post',
		data: parameter
	})
}
// 教务列表
export function getJiaoWuList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/list/jiaowu',
		method: 'get',
		params: parameter
	})
}
// 班型列表
export function getClassTypeList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/list/classType',
		method: 'get',
		params: parameter
	})
}
// 更新订单
export function updateOrder(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/order/updateOrder',
		method: 'post',
		data: parameter
	})
}
// 获取回访计划
export function getVisitList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/order/getVisitPlan',
		method: 'get',
		params: parameter
	})
}
// 新建回访计划
export function createVisit(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/order/newVisitPlan',
		method: 'post',
		data: parameter
	})
}
// 修改回访计划
export function updateVisit(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/order/updateVisitPlan',
		method: 'get',
		params: parameter
	})
}
// 回访记录列表
export function getVisitRecordList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/order/getVisitRecord',
		method: 'get',
		params: parameter
	})
}
// 新增回访记录
export function createVisitRecord(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/order/newVisitRecord',
		method: 'post',
		data: parameter
	})
}
// 上课记录页面
// 上课记录列表
export function getClassRecordList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/classRecordList',
		method: 'get',
		params: parameter
	})
}
// 新建上课记录
export function createRecord(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/newJiaowuRecord',
		method: 'post',
		data: parameter
	})
}
// 新建学生记录--快速识别
export function stuDist(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/stuDistinguish',
		method: 'post',
		data: parameter
	})
}
// 新增学生上课记录
export function createStudentRecord(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/newStuRecord',
		method: 'post',
		data: parameter
	})
}
// 编辑学生上课记录
export function updateStudentRecord(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/updateStuRecord',
		method: 'post',
		data: parameter
	})
}
// 新增研究生上课记录
export function createPostgraRecord(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/newPosRecord',
		method: 'post',
		data: parameter
	})
}
// 编辑研究生上课记录
export function updatePostgraRecord(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/updatePosRecord',
		method: 'post',
		data: parameter
	})
}
// 获取上课记录
export function getRecordList(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/getClassRecord',
		method: 'get',
		params: parameter
	})
}
// 新建学生上课记录--快速识别
export function stuDistinguish(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/stuDistinguish',
		method: 'post',
		data: parameter
	})
}
// 新建研究生上课记录--快速识别
export function posDistinguish(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/record/posDistinguish',
		method: 'post',
		data: parameter
	})
}
// 首页及消息提示
// 首页状态数量展示
export function getShowStatus(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/data/showStatusNum',
		method: 'get',
		params: parameter
	})
}
// 首页教务订单处理列表
export function getJWHandleNum(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/data/jwHandleNum',
		method: 'get',
		params: parameter
	})
}
// 消息通知页面列表
export function getInfoByClass(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/info/getInfoByClass',
		method: 'get',
		params: parameter
	})
}
// 消息推送列表
export function getPushInfo(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/info/pushInfo',
		method: 'get',
		params: parameter
	})
}
// 处理预警消息
export function handleInfo(parameter: any) {
	return Request.axiosInstance({
		url: '/api/crm/eas/info/handleInfo',
		method: 'get',
		params: parameter
	})
}

// 找研友审核-获取用户列表
export function getFriendList(params: any) {
	return Request.axiosInstance({
		url: '/findApi/mini/find/check/reviewInfoShow',
		method: 'get',
		params
	})
}
export function infoIsPass(params: any) {
	return Request.axiosInstance({
		url: '/findApi/mini/find/check/infoIsPass',
		method: 'post',
		data: params
	})
}
// 研究生信息审核
export function isPass(params: any) {
	return Request.axiosInstance({
		url: '/findApi/mini/find/smart/isPass',
		method: 'post',
		data: params
	})
}

export function getFriendListV2(params: any) {
	return Request.axiosInstance({
		url: '/api/crm/adv/getXuejieListV2',
		method: 'get',
		params
	})
}